/*----------------------------------------------------------------------------------------------------------------------------
# Routes.tsx: 
# This page controls the specific routing for the main CRC Portal application.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AirServicesTemplate from '@/components/_air/AirServices/AirServicesTemplate';
import LabsServicesTemplate from '@/components/_labs/LabsServices/LabsServicesTemplate';
import Insights from '@/components/Reliability/ReliabilityWrapper';
import { Navigation } from '@/constants/NavigationConstants';
import About from '@/pages/About/AboutTemplate';
import AccessibilityReport from '@/pages/AccessibilityReport/AccessibilityTemplate';
import Alerts from '@/pages/Alerts/AlertsTemplate';
import ChangeHistory from '@/pages/ChangeHistory/ChangeHistoryTemplate';
import Dashboards from '@/pages/Dashboards/DashboardsTemplate';
import ExperimentEditor from '@/pages/ExperimentEditor/ExperimentEditorTemplate';
import Favorites from '@/pages/Favorites/FavoritesTemplate';
import Issues from '@/pages/Issues/IssuesTemplate';
import LabDetailsHome from '@/pages/LabDetails/LabDetailsHomeTemplate';
import GanymedeLegacyHome from '@/pages/LegacyHome/LegacyHomeTemplate';
import Playground from '@/pages/Playground/PlaygroundTemplate';
import Settings from '@/pages/PortalSettings/PortalSettingsTemplate';
import ProjectDocumentation from '@/pages/ProjectDocumentation/ProjectDocumentationTemplate';
import ReleaseNotes from '@/pages/ReleaseNotes/ReleaseNotesTemplate';
import ResultExplorerHome from '@/pages/ResultExplorer/ResultExplorerHomeTemplate';
import SessionsHome from '@/pages/Sessions/SessionsTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

// These MUST be defined in order of overriding route preference.
const GanymedeRoutesFC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);

  const { appSettingsStore, userInfoStore } = rootStore;
  const { isDeveloperMode } = appSettingsStore;
  const { isMicrosoftAccount } = userInfoStore;

  return (
    <>
      <Route exact path={Navigation.GANYMEDE.ABOUT} component={About} />
      <Route exact path={Navigation.GANYMEDE.ALERTS} component={Alerts} />
      <Route exact path={Navigation.GANYMEDE.DASHBOARDS} component={Dashboards} />

      {/* // TODO: Implement temporary code for the Lab Details Page LHS. 
          // This method should be deprecated once active development on the Lab Details Page is complete.
          // Related ticket: https://msazure.visualstudio.com/One/_workitems/edit/28985759 */}
      {isDeveloperMode && (
        <>
          <Route exact path={Navigation.GANYMEDE.LAB_DETAILS} component={LabDetailsHome} />
        </>
      )}

      <Route exact path={Navigation.GANYMEDE.SESSIONS} component={SessionsHome} />
      <Route exact path={`${Navigation.GANYMEDE.SESSIONS}/:experimentId`} component={SessionsHome} />
      <Route exact path={`${Navigation.GANYMEDE.SESSIONS}/browse`} component={SessionsHome} />
      <Route exact path={Navigation.GANYMEDE.EXPERIMENTS} component={GanymedeLegacyHome} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENTS}/:type/:labId/:instanceId`} component={GanymedeLegacyHome} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENTS}/:labId`} component={GanymedeLegacyHome} />
      <Route exact path={Navigation.GANYMEDE.EXPERIMENT_EDITOR} component={ExperimentEditor} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENT_EDITOR}/:labId`} component={ExperimentEditor} />
      <Route exact path={Navigation.GANYMEDE.FAVORITES} component={Favorites} />
      <Route exact path={Navigation.GANYMEDE.PROJECT_DOCUMENTATION} component={ProjectDocumentation} />
      <Route exact path={Navigation.GANYMEDE.RELEASE_NOTES} component={ReleaseNotes} />
      <Route exact path={Navigation.GANYMEDE.RESULT_EXPLORER} component={ResultExplorerHome} />
      {isMicrosoftAccount && (
        <>
          <Route exact path={Navigation.GANYMEDE.CHANGE_HISTORY} component={ChangeHistory} />
          <Route exact path={Navigation.GANYMEDE.ACCESSIBILITY_REPORT} component={AccessibilityReport} />
          <Route exact path={Navigation.GANYMEDE.INSIGHTS} component={Insights} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND_AIR} component={AirServicesTemplate} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND_LABS} component={LabsServicesTemplate} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND} component={Playground} />
        </>
      )}
      <Route exact path={Navigation.GANYMEDE.ISSUES} component={Issues} />
      <Route exact path={Navigation.GANYMEDE.SETTINGS} component={Settings} />
      {/* The Root route must be last, as part of it is found in every other route. */}
      <Route exact path={Navigation.ROOT} component={SessionsHome} />
    </>
  );
};

const GanymedeRoutes = observer(GanymedeRoutesFC);

export { GanymedeRoutes };
