/*----------------------------------------------------------------------------------------------------------------------------
# Sessions.tsx: 
# This Component is our Sessions Component. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import SessionDetails from '@/components/SessionDetails/SessionDetailsWrapper';
import { SessionsRouteType } from '@/components/Sessions/SessionsTypes';
import SessionsWrapper from '@/components/Sessions/SessionsWrapper';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const SessionsFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, systemMessageStore } = rootStore;
  const { addGeneralFadingMessage, addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { clearSelectedSession, isDeepLinkMode, pathSessionId, setIsDeepLinkMode, setPathSessionId } = sessionsStore;
  const { isMockDataMode } = appSettingsStore;

  const { experimentId } = useParams<SessionsRouteType>();

  React.useEffect(() => {
    if (experimentId) {
      // We are in Deep Link Mode.
      setIsDeepLinkMode(true);
      setPathSessionId(experimentId);
    } else {
      setIsDeepLinkMode(false);
      setPathSessionId(null);
      clearSelectedSession();
    }
  }, [experimentId, pathSessionId, isDeepLinkMode, setIsDeepLinkMode, setPathSessionId]);

  const title = t('experiments', { ns: NS.EXPERIMENTS });
  const experimentsLink = (
    <>
      <Link to={{ pathname: Navigation.GANYMEDE.SESSIONS }}>{t('all-sessions', { ns: NS.EXPERIMENTS })}</Link> {TITLE_DIVIDER}{' '}
      {experimentId}
    </>
  );
  const subTitle = isDeepLinkMode ? experimentsLink : t('all-sessions', { ns: NS.EXPERIMENTS });
  const dataMode = isMockDataMode ? 'mock-data-on' : 'mock-data-off';

  React.useEffect(() => {
    addGeneralFadingMessage(t(dataMode, { ns: NS.EXPERIMENTS }), dataMode);
  }, [isMockDataMode]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [addHelpMessage, clearNonPersistentGlobalMessages]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.SESSIONS]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={false}>
            <div className="fullscreen">
              {!isDeepLinkMode && <SessionsWrapper></SessionsWrapper>}
              {isDeepLinkMode && <SessionDetails sessionId={experimentId}></SessionDetails>}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const Sessions = observer(SessionsFC);

export default Sessions;
