import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ChoiceGroup, CommandBarButton, DatePicker, DefaultButton, Dropdown, SearchBox } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { CheckboxVisibility, IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { t } from 'i18next';

import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { TimeRangeOptions } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import {
  ColumnEditorKeys,
  EnablePagination,
  FilterOptions,
  Namespaces as NS,
  Pagination,
  ReadingPaneKeys,
  ReadingPanePositionType,
  ReadingPaneStateKeys,
  SortedColumnPageKeys,
} from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { ColumnEditorUserSettingsType } from '@/partials/ColumnEditorPanel/ColumnEditorPanelTypes';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { getPaginationDefaults } from '@/partials/Pagination/Pagination';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { formatDateTime, formatDateToISOStandard, getRunTimeRangeDates } from '@/utils/Dates';
import { createGroupByNoneItem } from '@/utils/GroupBy';
import { navigationOnClick } from '@/utils/Helpers';
import { processColumns } from '@/utils/Tables';
import { useWindowSize } from '@/utils/UseWindowSize';

import tableConfig from './Sessions.config.json';
import SessionsTemplate from './SessionsTemplate';
import { SessionsVMType, SessionType, SplitPanelLayoutConfigType } from './SessionsTypes';

import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface SessionsViewControllerProps {
  viewModel: SessionsVMType;
  openPanelOnRowSelect: boolean;
}

let requestIdCounter = 0;

const generateRequestId = () => {
  return ++requestIdCounter;
};

const SessionsViewControllerFC: React.FC<SessionsViewControllerProps> = ({ viewModel, openPanelOnRowSelect }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const {
    appSettingsStore,
    editColumnsStore,
    localStorage,
    paginationStore,
    sessionsStore,
    sessionDetailsStore,
    tableViewStore,
    userSettingsStore,
  } = rootStore;
  const { isOutlookMode, isPartnerMode, readingPanePosition, setPreviewPanePosition } = appSettingsStore;
  const { setPaginationType } = paginationStore;
  const {
    doReset,
    endDate,
    groupByColumn,
    groupByValue,
    ipAddresses,
    isIpAddressLoading,
    isLocationsLoading,
    isSessionsLoading,
    labs,
    lastRunTimeRange,
    locations,
    locationValue,
    pageSize,
    pageSplitDirection,
    resetLocation,
    searchValue,
    startDate,
    statusValues,
    sessions,
    sessionGroups,
    setGroupByValue,
    setSearchValue,
    setSelectedSession,
    setStartDate,
    setEndDate,
    setLastRunTimeRange,
  } = sessionsStore;
  const {
    closeSessionColumnEditor,
    isSessionColumnEditorOpen,
    openSessionColumnEditor,
    openSessionPanel,
    sessionColumnList,
    sessionEntireColumns,
    setSessionColumnList,
    setSessionEntireColumns,
  } = sessionDetailsStore;

  const { loadIpAddresses, loadLocations, loadSessions, setCompanyName, setGroupByData } = viewModel;
  const history = useHistory();
  const windowSize = useWindowSize();
  const { timeZone } = userSettingsStore;
  const { doResetTableSort } = tableViewStore;
  const pageName = SortedColumnPageKeys.SESSION_PAGE;

  const columnDefinitions = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
  const [panelMessage, setPanelMessage] = React.useState<string>(null);

  const latestRequestIdRef = React.useRef(0);
  const isInitialRender = React.useRef(true);
  const columnEditorKey: string = ColumnEditorKeys.SESSIONS;
  const groupByNoneKey = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });

  const cancellationToken = useCancellationToken();

  React.useEffect(() => {
    setCompanyName();
  }, [sessions, labs, locationValue]);

  // Reset the data to the original state without filters
  const handleClearAllFilters = () => {
    doResetSessionLayout();
    doResetTableSort(pageName);
    doReset();
  };

  const handleSingleRowClick = async (item: SessionType) => {
    setSelectedSession(item);
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      return selectRow(selection);
    },
  });

  const selectRow = (item: Selection) => {
    const selectedItems: IObjectWithKey[] = item.getSelection();
    const selection: SessionType | null = selectedItems[0] as SessionType | null;
    const isOneRowSelected: boolean = selectedItems?.length === 1;

    if (isOneRowSelected) {
      const item: SessionType = selection;

      handleSingleRowClick(item);

      if (openPanelOnRowSelect) {
        openSessionPanel();
      }
    }
  };

  const fetchSessions = React.useCallback(
    async (currentPage?: number, currentSearchValue?: string) => {
      const selectedPageSize = pageSize;

      currentPage = currentPage || Pagination.CURRENT_PAGE;
      currentSearchValue = currentSearchValue !== undefined ? currentSearchValue : searchValue;

      if (locationValue !== '') {
        const filters = {
          searchValue: currentSearchValue,
          lastRunTimeRange,
          startDate: startDate ? formatDateToISOStandard(startDate) : startDate,
          endDate: endDate ? formatDateToISOStandard(endDate) : endDate,
        };

        const requestId = generateRequestId();
        latestRequestIdRef.current = requestId;

        loadSessions(latestRequestIdRef, requestId, selectedPageSize, currentPage, filters, cancellationToken);
      }
    },
    [endDate, ipAddresses, isPartnerMode, lastRunTimeRange, locationValue, pageSize, startDate, statusValues],
  );

  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Skip the first render and call function when depency changes
      return;
    }

    fetchSessions();
  }, [endDate, lastRunTimeRange, startDate]);

  const fetchSessionsByName = (currentSearchValue: string, isFetchSession: boolean) => {
    setSearchValue(currentSearchValue);

    if (isFetchSession || currentSearchValue === '') {
      fetchSessions(null, currentSearchValue);
    }
  };

  const handlePaginationChange = (pageIndex: number): void => {
    fetchSessions(pageIndex);
  };

  const onSelectRange = (key: string) => {
    setLastRunTimeRange(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      const { runTimeStartDate, runTimeEndDate } = getRunTimeRangeDates(key, timeZone);

      setStartDate(runTimeStartDate);
      setEndDate(runTimeEndDate);
    }
  };

  const groupByProps: ICommandBarItemProps[] = columnDefinitions
    .filter((item) => item.isGroupBy)
    .map((item) => {
      const groupByName = t(item.name, { ns: NS.TABLE });

      return {
        key: item.key,
        text: groupByName,
        onClick: () => handleGroupBySelected(item.key),
      };
    });

  const handleGroupBySelected = (groupByColumn: string) => {
    setGroupByValue(groupByColumn);
    setGroupByData(sessions, groupByColumn);
  };

  const groupByNoneItem = createGroupByNoneItem(handleGroupBySelected);

  const disableFilterButtons = React.useCallback(() => {
    return isSessionsLoading || isLocationsLoading || isIpAddressLoading;
  }, [isSessionsLoading, isLocationsLoading, isIpAddressLoading]);

  // Settings for Session Experiments Table Column Editor.
  React.useEffect(() => {
    const { getEditorColumns } = editColumnsStore;
    const storedColumns = getEditorColumns(columnEditorKey);
    const { userColumns, allColumns } = processColumns(storedColumns, columnDefinitions);
    const paginationDefaults = getPaginationDefaults(handlePaginationChange, EnablePagination.SESSIONS, false);

    setSessionColumnList(userColumns);
    setSessionEntireColumns(allColumns);
    setPaginationType(paginationDefaults);
  }, []);

  React.useEffect(() => {
    loadLocations(null, isPartnerMode);
  }, [isPartnerMode]);

  React.useEffect(() => {
    if (locationValue !== '') {
      fetchSessions(null, searchValue);
      loadIpAddresses(cancellationToken);
    }
  }, [locationValue, pageSize]);

  if (!sessions) {
    return <LoadingSpinner />;
  }

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [],
  };

  const isRightChecked = isOutlookMode && readingPanePosition === ReadingPanePositionType.RIGHT;
  const isBottomChecked = isOutlookMode && readingPanePosition === ReadingPanePositionType.BOTTOM;
  const isHideChecked = !(isRightChecked || isBottomChecked);

  const onLayoutClick = (event?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
    event && event.preventDefault();

    if (item) {
      if (item.key === ReadingPaneKeys.SHOW_RIGHT) {
        setPreviewPanePosition(ReadingPanePositionType.RIGHT);
      } else if (item.key === ReadingPaneKeys.SHOW_BOTTOM) {
        setPreviewPanePosition(ReadingPanePositionType.BOTTOM);
      } else if (item.key === ReadingPaneKeys.HIDE) {
        setPreviewPanePosition(ReadingPanePositionType.HIDE);
      }
    }
  };

  const layoutItems: IContextualMenuProps = {
    items: [
      {
        key: 'readingPane',
        text: 'Reading Pane',
        subMenuProps: {
          items: [
            {
              key: ReadingPaneKeys.SHOW_RIGHT,
              text: t(ReadingPaneKeys.SHOW_RIGHT, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isRightChecked,
              onClick: onLayoutClick,
            },
            {
              key: ReadingPaneKeys.SHOW_BOTTOM,
              text: t(ReadingPaneKeys.SHOW_BOTTOM, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isBottomChecked,
              onClick: onLayoutClick,
            },
            {
              key: ReadingPaneKeys.HIDE,
              text: t(ReadingPaneKeys.HIDE, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isHideChecked,
              onClick: onLayoutClick,
            },
          ],
        },
      },
    ],
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'create-experiment',
      text: t('create-experiment', { ns: NS.EXPERIMENTS }),
      title: t('create-experiment', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        navigationOnClick(event, Navigation.GANYMEDE.EXPERIMENT_EDITOR, history);
      },
    },
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    // NOTE: Placeholder. We will comment this out until Instance Management is designed.
    // {
    //   key: 'instance-management',
    //   text: t('instance-management', { ns: NS.EXPERIMENTS }),
    //   title: t('instance-management', { ns: NS.EXPERIMENTS }),
    //   iconProps: { iconName: SystemIcons.INSTANCE_MANAGEMENT },
    //   disabled: true,
    //   onClick: (event) => {
    //     event.preventDefault();
    //     // Manage instances.
    //   },
    // },
    // {
    //   key: 'divider-2',
    //   commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    // },
    {
      key: 'edit-columns',
      text: t('edit-columns', { ns: NS.EXPERIMENTS }),
      title: t('edit-columns', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        openSessionColumnEditor();
      },
    },
  ];

  const divider = {
    key: 'divider-3',
    commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
  };

  const layoutButton: ICommandBarItemProps = {
    key: 'layout',
    onRender: () => (
      <CommandBarButton
        text={t('layout', { ns: NS.EXPERIMENTS })}
        iconProps={{ iconName: SystemIcons.READING_PANE }}
        menuProps={layoutItems}
        role="menuitem"
        className={filterBarStyles['pagefilterbar-dropdown']}
        styles={filterBar.dropdown}
      />
    ),
  };

  // Add the Layout button with divider when we're in Outlook mode.
  if (isOutlookMode) {
    commandBarItems.push(...[divider, layoutButton]);
  }

  const dateFilterMenuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={lastRunTimeRange}
            options={TimeRangeOptions}
            onChange={(ev, option) => {
              onSelectRange(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={lastRunTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setStartDate}
              value={startDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(startDate.toString(), lastRunTimeRange, timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={lastRunTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setEndDate}
              value={endDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(endDate.toString(), lastRunTimeRange, timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-experiment-name', { ns: NS.EXPERIMENTS })}
          title={t('search-experiment-name', { ns: NS.EXPERIMENTS })}
          role={'none'}
          value={searchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event, value) => fetchSessionsByName(value, false)}
          onSearch={(value) => {
            fetchSessionsByName(value, true);
          }}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
          // disabled={disableFilterButtons} /* Enabling this causes some consoles errors to fix later */
        />
      ),
    },
    {
      key: 'location-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-location', { ns: NS.COMMON })}
          title={t('select-location', { ns: NS.COMMON })}
          dropdownWidth="auto"
          selectedKey={locationValue}
          options={locations}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) => resetLocation(option.key)}
          disabled={false}
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('select-created-time', { ns: NS.COMMON })}
          title={t('select-created-time', { ns: NS.COMMON })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={dateFilterMenuProps}
          onMenuClick={(ev, option) => {
            onSelectRange(lastRunTimeRange);
          }}
        />
      ),
    },
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'clear-all-filters',
      onRender: () => (
        <CommandBarButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAllFilters}
        />
      ),
    },
    {
      key: 'group-by-column',
      onRender: () => (
        <CommandBarButton
          text={t(groupByValue, { ns: NS.TABLE }) || groupByNoneKey}
          iconProps={{ iconName: SystemIcons.GROUP_LIST }}
          menuProps={{ items: [groupByNoneItem, ...groupByProps] }}
        />
      ),
    },
  ];

  const overflowItems: ICommandBarItemProps[] = [];

  const columnEditorUserSettings: ColumnEditorUserSettingsType = {
    headerText: t('choose-session-columns', { ns: NS.TITLES }),
  };

  const getDimensions = (): number[] => {
    const horizontal = windowSize.height;
    const vertical = windowSize.width;
    const coordinates: number[] = [Math.round(horizontal / 2), Math.round(vertical / 2)];

    return coordinates;
  };

  const layoutConfig: SplitPanelLayoutConfigType = {
    // Layout for Outlook mode when the Reading Pane is on the bottom.
    horizontal: {
      defaultSize: [getDimensions()[0]],
      minSize: [250],
      maxSize: [-250], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
      keys: [ReadingPaneStateKeys.SESSION_LIST_HORIZONTAL],
      offModeSize: ['100%'],
      padding: 250, // Padding to prevent the pane from being resized to the full height of the window.
    },

    // Layout for Outlook mode when the Reading Pane is on the right.
    vertical: {
      defaultSize: [getDimensions()[1]],
      minSize: [250],
      maxSize: [-250], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
      keys: [ReadingPaneStateKeys.SESSION_LIST_VERTICAL],
      offModeSize: ['100%'],
      padding: 250, // Padding to prevent the pane from being resized to the full height of the window.
    },
  };

  const doResetSessionLayout = () => {
    const coordinates = getDimensions();

    localStorage.setValue(ReadingPaneStateKeys.SESSION_LIST_COORDINATES, coordinates);
    localStorage.setValue(ReadingPaneStateKeys.SESSION_LIST_HORIZONTAL, coordinates[0]);
    localStorage.setValue(ReadingPaneStateKeys.SESSION_LIST_VERTICAL, coordinates[1]);

    setPreviewPanePosition(ReadingPanePositionType.BOTTOM);
  };

  const splitPanelConfig: SplitPanelConfigType = layoutConfig[pageSplitDirection as string];

  return (
    <SessionsTemplate
      tableItems={sessions}
      tableGroups={sessionGroups}
      groupByColumn={groupByColumn}
      groupByColumns={columnDefinitions}
      overflowItems={overflowItems}
      commandBarItems={commandBarItems}
      filterItems={filterItems}
      farItems={farItems}
      tableConfig={tableConfig}
      panelLoadErrorMessage={panelMessage}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick={true}
      splitPanelConfig={splitPanelConfig}
      checkboxVisibility={CheckboxVisibility.hidden}
      columnsList={sessionColumnList}
      setColumnsList={setSessionColumnList}
      entireColumns={sessionEntireColumns}
      columnEditorKey={columnEditorKey}
      isLoading={isSessionsLoading}
      isColumnEditorOpen={isSessionColumnEditorOpen}
      hideColumnEditor={closeSessionColumnEditor}
      columnEditorUserSettings={columnEditorUserSettings}
      pageName={pageName}
    ></SessionsTemplate>
  );
};

const SessionsViewController = observer(SessionsViewControllerFC);

export default SessionsViewController;
