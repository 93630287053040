import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CommandBarButton, Icon, TextField } from '@fluentui/react';
import { MessageBarType } from '@fluentui/react';
import { Link } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';

import { LoadingSpinner } from '@/components/_air/common';
import { LabUpgradeComponent } from '@/components/_labs/LabsHelper/ModalComponents/LabUpgradeComponent';
import { LabDetailsTemplate } from '@/components/ManageLab/LabDetails/LabDetailsTemplate';
import { LabDetailsVMType } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import LabGeneralViewController from '@/components/ManageLab/LabGeneral/LabGeneralViewController';
import LabGeneralViewModel from '@/components/ManageLab/LabGeneral/LabGeneralViewModel';
import LabSystemsViewController from '@/components/ManageLab/LabSystems/LabSystemsViewController';
import LabSystemsViewModel from '@/components/ManageLab/LabSystems/LabSystemsViewModel';
import { SystemIcons } from '@/constants/IconConstants';
import { Labels } from '@/constants/LabDetailsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { ExportExcelFileName, IconNames, Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import { Common, Editor, LabDetails, Labs } from '@/constants/TranslationConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { useCancellationToken } from '@/services/_labs/screen-service';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { exportToExcel } from '@/utils/Helpers';

import styles from '@/components/ManageLab/LabDetails/LabDetails.module.css';

interface LabDetailsViewControllerProps {
  viewModel: LabDetailsVMType;
}

const LabDetailsViewControllerFC: React.FC<LabDetailsViewControllerProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsStore, systemMessageStore } = rootStore;
  const { tabMemory } = appSettingsStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { fetchLabManifest, fetchLabs, handleLabChange, upgradeLab } = viewModel;
  const {
    createMessage,
    failedExperiments,
    hasFailedExperiments,
    hasSuccessfulExperiments,
    isExperimentLoading,
    isLabBusy,
    isLabUpgradeModalOpen,
    labList,
    manifestVersion,
    selectedLab,
    setFailedExperiments,
    setIsLabUpgradeModalOpen,
    setSuccessfulExperiments,
    successfulExperiments,
    updateAvailable,
  } = labDetailsStore;

  const labGeneralViewModel = useMemo(() => new LabGeneralViewModel(rootStore), [rootStore]);
  const labSystemsViewModel = useMemo(() => new LabSystemsViewModel(rootStore), [rootStore]);

  const [searchText, setSearchText] = useState('');

  const pageName = TabMemoryKeys.LAB_DETAILS;
  const activeTab: string = tabMemory[pageName as string];
  const title = t(Labs.LABS, { ns: NS.LABS });

  const systemMessage: SystemMessageType[] = globalMessages?.filter((message: SystemMessageType) => !message.showInPopup);

  const cancellationToken = useCancellationToken();

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (fetchLabs) {
      fetchLabs();
    }

    if (!selectedLab?.IsVirtualLab) {
      fetchLabManifest();
    }
  }, [fetchLabs, fetchLabManifest]);

  if (isLabBusy) {
    return <LoadingSpinner />;
  }

  if (labList?.length > 0 && selectedLab) {
    const defaultLabName = (
      <div>
        <span className={styles['labs-dropdown']}>{selectedLab?.LabName}</span>
      </div>
    );

    const filteredLabs = labList?.filter((lab) => lab.LabName?.toLowerCase().includes(searchText.toLowerCase()));

    const commandbar = (
      <CommandBarButton
        text={defaultLabName}
        menuProps={{
          items: [
            {
              key: 'searchBox',
              onRender: () => (
                <TextField
                  placeholder={t(LabDetails.SEARCH_LAB, { ns: NS.LAB_DETAILS })}
                  value={searchText}
                  onChange={(e, newValue) => setSearchText(newValue || '')}
                  prefix={<Icon iconName={IconNames.SEARCH} />}
                />
              ),
            },
            ...filteredLabs.map((lab) => ({
              key: lab.LabId.toString(),
              text: lab.LabName,
              onClick: () => {
                handleLabChange(lab);
              },
            })),
          ],
        }}
      />
    );

    const components = [
      {
        headerText: t(LabDetails.GENERAL, { ns: NS.LAB_DETAILS }),
        viewModel: labGeneralViewModel,
        component: LabGeneralViewController,
      },
      {
        headerText: t(LabDetails.SYSTEMS, { ns: NS.LAB_DETAILS }),
        viewModel: labSystemsViewModel,
        component: LabSystemsViewController,
      },
    ];

    const isNotNewLab = selectedLab?.LabStatus !== Labels.NEW;
    const notifyUpdateIsAvailable = updateAvailable && isNotNewLab && !selectedLab?.IsVirtualLab;

    const displayLabUpgradeMessage = () => {
      const message = (
        <>
          {t(LabDetails.NEW_VERSION, { ns: NS.LAB_DETAILS })} {manifestVersion + ' '}
          <Link onClick={() => upgradeLab(cancellationToken)} underline>
            {t(Common.CLICK_TO_UPDATE, { ns: NS.COMMON })}
          </Link>
        </>
      );

      const warningMessage: SystemMessageType = {
        message: message,
        type: MessageBarType.warning,
        mode: MessageBarMode.cannotDismiss,
        persistent: true,
      };

      addGlobalMessage(warningMessage);
    };

    const labUpgradeCommandItems: ICommandBarItemProps[] = [
      {
        key: t(Editor.DOWNLOAD_SUCCESS_REPORT, { ns: NS.EDITOR }),
        text: t(Editor.DOWNLOAD_SUCCESS_REPORT, { ns: NS.EDITOR }),
        ariaLabel: t(Editor.DOWNLOAD_SUCCESS_REPORT, { ns: NS.EDITOR }),
        iconOnly: false,
        iconProps: { iconName: SystemIcons.DOWNLOAD },
        title: t(Editor.DOWNLOAD_SUCCESS_REPORT, { ns: NS.EDITOR }),
        disabled: !hasSuccessfulExperiments,
        onClick: (event) => {
          exportToExcel(successfulExperiments, ExportExcelFileName.SUCCESSFUL_INSTANCE);
        },
      },
      {
        key: t(Editor.DOWNLOAD_FAILED_REPORT, { ns: NS.EDITOR }),
        text: t(Editor.DOWNLOAD_FAILED_REPORT, { ns: NS.EDITOR }),
        ariaLabel: t(Editor.DOWNLOAD_FAILED_REPORT, { ns: NS.EDITOR }),
        iconOnly: false,
        iconProps: { iconName: SystemIcons.DOWNLOAD },
        title: t(Editor.DOWNLOAD_FAILED_REPORT, { ns: NS.EDITOR }),
        disabled: !hasFailedExperiments,
        onClick: (event) => {
          exportToExcel(failedExperiments, ExportExcelFileName.FAILED_INSTANCE);
        },
      },
    ];

    const hideModal = () => {
      setIsLabUpgradeModalOpen(false);
      setFailedExperiments([]);
      setSuccessfulExperiments([]);
    };

    if (isLabUpgradeModalOpen) {
      return (
        <LabUpgradeComponent
          isModalOpen={isLabUpgradeModalOpen}
          hideModal={hideModal}
          experimentLoading={isExperimentLoading}
          commandItems={labUpgradeCommandItems}
          creationMessage={createMessage}
          successfulExperiments={successfulExperiments}
          failedExperiments={failedExperiments}
        />
      );
    }

    return (
      <>
        <MessageBarTemplate>{systemMessage}</MessageBarTemplate>
        <main className="fullscreen container ganymede-wrapper">
          <div className="fullscreen padding-top">
            <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={commandbar}>
              {title}
            </PageHeader>
            <PageContent scrollable={false}>
              <div className="fullscreen">
                {notifyUpdateIsAvailable && displayLabUpgradeMessage()}
                <LabDetailsTemplate components={components} activeTab={activeTab} />
              </div>
            </PageContent>
          </div>
        </main>
      </>
    );
  }
};

const LabDetailsViewController = observer(LabDetailsViewControllerFC);

export default LabDetailsViewController;
