import { action, computed, makeObservable, observable } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { t } from 'i18next';

import config from '@/components/Sessions/Sessions.config.json';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionStepJsonType, SessionStepType } from '@/components/Sessions/SessionsTypes';
import { SessionLogResultsType } from '@/components/Sessions/SessionsTypes';
import { CancellableStatuses, StatusesWithLogs } from '@/constants/ExperimentConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnEditorKeys, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableColumnType } from '@/types/TableTypes';

class SessionDetailsStore {
  protected rootStore: RootStore;
  protected appSettingsStore: AppSettingsStore;
  protected sessionsStore: SessionsStore;
  protected localStorage: LocalStorageService;

  public static SESSION_COLUMN_DEFINITIONS = config.sessionsColumnDefinitions;
  public static SESSION_STEPS_COLUMN_DEFINITIONS = config.stepsColumnDefinitions;
  public static SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS = config.sessionExperimentFailureDefinitions;

  public isCancelButtonDisabled: boolean;
  public isDownloadButtonDisabled: boolean;
  public isLogWindowItemSelected: boolean;
  public isSessionColumnEditorOpen: boolean;
  public isSessionLoading: boolean;
  public isSessionModalOpen: boolean;
  public isSessionPanelOpen: boolean;
  public isSessionStepColumnEditorOpen: boolean;
  public isSessionStepPopupOpen: boolean;
  public isSessionStepWindowSelected: boolean;
  public isSessionFailureWindowSelected: boolean;
  public logPath: string;
  public sessionColumnList: TableColumnType[];
  public sessionEntireColumns: TableColumnType[];
  public sessionExperimentFailureColumns: TableColumnType[];
  public sessionLogs: SessionLogResultsType[];
  public sessionPanelMessages: SystemMessageType[];
  public sessionStep: SessionStepType;
  public sessionStepColumnList: TableColumnType[];
  public sessionStepColumnEditorKey: string;
  public sessionStepDetails: SessionStepJsonType;
  public sessionStepEntireColumns: TableColumnType[];
  public sessionStepGroupByValue: string;
  public sessionStepGroupByColumn: string;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables for individual Sessions.
      sessionStep: observable,
      isCancelButtonDisabled: observable,
      isDownloadButtonDisabled: observable,
      isLogWindowItemSelected: observable,
      isSessionColumnEditorOpen: observable,
      isSessionModalOpen: observable,
      isSessionPanelOpen: observable,
      isSessionStepColumnEditorOpen: observable,
      isSessionStepPopupOpen: observable,
      isSessionStepWindowSelected: observable,
      isSessionFailureWindowSelected: observable,
      logPath: observable,
      sessionLogs: observable,
      sessionColumnList: observable,
      sessionEntireColumns: observable,
      sessionExperimentFailureColumns: observable,
      sessionPanelMessages: observable,
      sessionStepColumnList: observable,
      sessionStepColumnEditorKey: observable,
      sessionStepDetails: observable,
      sessionStepEntireColumns: observable,
      sessionStepGroupByValue: observable,
      sessionStepGroupByColumn: observable,

      // Actions modify the state.
      addSessionPanelMessage: action,
      clearSelectedSessionStep: action,
      clearSessionPanelMessages: action,
      closeSessionColumnEditor: action,
      closeSessionModal: action,
      closeSessionPanel: action,
      closeSessionStepColumnEditor: action,
      closeSessionStepPopup: action,
      disableCancelButton: action,
      disableDownloadButton: action,
      enableCancelButton: action,
      enableDownloadButton: action,
      openSessionColumnEditor: action,
      openSessionModal: action,
      openSessionPanel: action,
      openSessionStepColumnEditor: action,
      openSessionStepPopup: action,
      selectLogWindowItem: action,
      selectSessionStepWindow: action,
      setCurrentSessionStep: action,
      setIsCancelButtonDisabled: action,
      setIsDownloadButtonDisabled: action,
      setIsLogWindowItemSelected: action,
      setIsSessionColumnEditorOpen: action,
      setIsSessionFailureWindowSelected: action,
      setIsSessionLoading: action,
      setIsSessionModalOpen: action,
      setIsSessionPanelOpen: action,
      setIsSessionStepColumnEditorOpen: action,
      setIsSessionStepPopupOpen: action,
      setIsSessionStepWindowSelected: action,
      setLogPath: action,
      setSessionColumnList: action,
      setSessionEntireColumns: action,
      setSessionExperimentFailureColumns: action,
      setSessionStepGroupByColumn: action,
      setSessionLogs: action,
      setSessionStep: action,
      setSessionStepColumnsList: action,
      setSessionStepEntireColumns: action,
      setSessionStepGroupByValue: action,
      toggleSessionPanel: action,
      toggleSessionColumnEditor: action,
      unmountSessionDetails: action,

      // Set computed properties.
      canCancelSession: computed,
      canViewSessionSteps: computed,
      canViewInstanceResults: computed,
      hasPanelMessages: computed,
      isAirSession: computed,
      isLabsSession: computed,
      isSessionStepsSelected: computed,
      isSessionStepsButtonDisabled: computed,
      panelMessageCount: computed,
      selectedSessionRoutePath: computed,
      sessionHasLogs: computed,
      stepCount: computed,
    });

    this.rootStore = rootStore;
    this.appSettingsStore = rootStore.appSettingsStore;
    this.sessionsStore = rootStore.sessionsStore;
    this.localStorage = rootStore.localStorage;

    // Set initial state for these observables.
    this.sessionStep = undefined;
    this.isCancelButtonDisabled = false;
    this.isDownloadButtonDisabled = false;
    this.isLogWindowItemSelected = false;
    this.isSessionColumnEditorOpen = false;
    this.isSessionLoading = false;
    this.isSessionModalOpen = false;
    this.isSessionPanelOpen = false;
    this.isSessionStepColumnEditorOpen = false;
    this.isSessionStepPopupOpen = false;
    this.isSessionStepWindowSelected = false;
    this.isSessionFailureWindowSelected = false;
    this.sessionColumnList = [] as TableColumnType[];
    this.sessionEntireColumns = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
    this.sessionExperimentFailureColumns = SessionDetailsStore.SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS;
    this.sessionLogs = undefined;
    this.sessionStepColumnEditorKey = ColumnEditorKeys.SESSION_STEP;
    this.sessionStepColumnList = [] as TableColumnType[];
    this.sessionStepDetails = null;
    this.sessionStepEntireColumns = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;
    this.sessionStepGroupByValue =
      this.localStorage.getValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY) || t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
    this.sessionStepGroupByColumn = '';
    this.sessionPanelMessages = [] as SystemMessageType[];
  }

  public addSessionPanelMessage = (message: string | SystemMessageType) => {
    if (typeof message === 'string') {
      const systemMessage: SystemMessageType = {
        message: message,
        type: FluentMessageBarType.success,
        mode: MessageBarMode.normal,
        groupId: 'session-panel-message',
      };

      this.sessionPanelMessages.push(systemMessage as SystemMessageType);
    } else if (message) {
      this.sessionPanelMessages.push(message as SystemMessageType);
    }
  };

  public clearSelectedSessionStep = () => {
    this.setSessionStep(null);
    this.setCurrentSessionStep(null);
    this.setIsSessionStepPopupOpen(false);
  };

  public clearSessionPanelMessages = () => {
    this.sessionPanelMessages = [] as SystemMessageType[];
  };

  public closeSessionColumnEditor = () => {
    this.setIsSessionColumnEditorOpen(false);
  };

  public closeSessionModal = () => {
    this.setIsSessionModalOpen(false);
  };

  public closeSessionPanel = () => {
    this.setIsSessionPanelOpen(false);
    this.closeSessionStepColumnEditor(); // Since the Panel is closed, close any open Column Editors in the Panel.
    this.clearSessionPanelMessages();
    this.enableCancelButton();
    this.enableDownloadButton();
  };

  public closeSessionStepColumnEditor = () => {
    this.setIsSessionStepColumnEditorOpen(false);
  };

  public closeSessionStepPopup = () => {
    this.setIsSessionStepPopupOpen(false);
  };

  public disableCancelButton = () => {
    this.setIsCancelButtonDisabled(true);
  };

  public disableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(true);
  };

  public enableCancelButton = () => {
    this.setIsCancelButtonDisabled(false);
  };

  public enableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(false);
  };

  public openSessionColumnEditor = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionColumnEditorOpen(true);
    this.closeSessionPanel(); // Since the user wants to Edit Columns for the parent page, close the Panel.

    closeSettings();
  };

  public openSessionModal = () => {
    this.closeSessionPanel();
    this.setIsSessionModalOpen(true);
  };

  public openSessionPanel = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionPanelOpen(true);
    this.closeSessionColumnEditor(); // Since the Panel is open, close any open Column Editors in the main page.

    closeSettings();
  };

  public openSessionStepColumnEditor = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionStepColumnEditorOpen(true);

    closeSettings();
  };

  public openSessionStepPopup = () => {
    if (this.canViewSessionSteps) {
      this.setIsSessionStepPopupOpen(true);
    }
  };

  public selectLogWindowItem = () => {
    this.setIsLogWindowItemSelected(true);
    this.setIsSessionStepWindowSelected(false);
    this.setIsSessionFailureWindowSelected(false);
  };

  public selectSessionStepWindow = () => {
    this.setIsLogWindowItemSelected(false);
    this.setIsSessionStepWindowSelected(true);
    this.setIsSessionFailureWindowSelected(false);
  };

  public selectFailureWindowItem = () => {
    this.setIsSessionFailureWindowSelected(true);
    this.setIsLogWindowItemSelected(false);
    this.setIsSessionStepWindowSelected(false);
  };

  public setCurrentSessionStep = (value: SessionStepJsonType) => {
    this.sessionStepDetails = value;
  };

  public setIsCancelButtonDisabled = (value: boolean) => {
    this.isCancelButtonDisabled = value;
  };

  public setIsDownloadButtonDisabled = (value: boolean) => {
    this.isDownloadButtonDisabled = value;
  };

  public setIsLogWindowItemSelected = (value: boolean) => {
    this.isLogWindowItemSelected = value;
  };

  public setIsSessionStepPopupOpen = (value: boolean) => {
    this.isSessionStepPopupOpen = value;
  };

  public setIsSessionLoading = (value: boolean) => {
    this.isSessionLoading = value;
  };

  public setIsSessionModalOpen = (value: boolean) => {
    this.isSessionModalOpen = value;
  };

  public setIsSessionPanelOpen = (value: boolean) => {
    this.isSessionPanelOpen = value;
  };

  public setIsSessionColumnEditorOpen = (value: boolean) => {
    this.isSessionColumnEditorOpen = value;
  };

  public setIsSessionStepColumnEditorOpen = (value: boolean) => {
    this.isSessionStepColumnEditorOpen = value;
  };

  public setIsSessionStepWindowSelected = (value: boolean) => {
    this.isSessionStepWindowSelected = value;
  };

  public setIsSessionFailureWindowSelected = (value: boolean) => {
    this.isSessionFailureWindowSelected = value;
  };

  public setLogPath = (value: string) => {
    this.logPath = value;
  };

  public setSessionColumnList = (value: TableColumnType[]) => {
    this.sessionColumnList = value;
  };

  public setSessionEntireColumns = (value: TableColumnType[]) => {
    this.sessionEntireColumns = value;
  };

  public setSessionExperimentFailureColumns = (value: TableColumnType[]) => {
    this.sessionExperimentFailureColumns = value;
  };

  public setSessionLogs = (value: SessionLogResultsType[]) => {
    this.sessionLogs = value;
  };

  public setSessionStep = (value: SessionStepType) => {
    this.sessionStep = value;
  };

  public setSessionStepGroupByColumn = (value: string) => {
    this.sessionStepGroupByColumn = value;
  };

  public setSessionStepColumnsList = (value: TableColumnType[]) => {
    this.sessionStepColumnList = value;
  };

  public setSessionStepEntireColumns = (value: TableColumnType[]) => {
    this.sessionStepEntireColumns = value;
  };

  public setSessionStepGroupByValue = (value: string): string => {
    this.sessionStepGroupByValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY, value);
  };

  public toggleSessionPanel = () => {
    this.isSessionPanelOpen ? this.closeSessionPanel() : this.openSessionPanel();
  };

  public toggleSessionColumnEditor = () => {
    this.isSessionColumnEditorOpen ? this.closeSessionColumnEditor() : this.openSessionColumnEditor();
  };

  public unmountSessionDetails = () => {
    // Cleanup Session Details when we unmount.
    this.closeSessionModal();
    this.closeSessionPanel();
    this.closeSessionStepPopup();
    this.closeSessionStepColumnEditor();
    this.clearSelectedSessionStep();
    this.clearSessionPanelMessages();
  };

  public get canCancelSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!selectedSession || this.isAirSession) {
      return false;
    }

    const status = isSessionSelected && selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const canCancel = CancellableStatuses.includes(status);

    return canCancel;
  }

  public get canViewInstanceResults(): boolean {
    // If the Experiment Instance has logs, and the status is failed or succeeded,
    // the results can be viewed.
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!isSessionSelected) {
      return false;
    }

    const hasLogs = !!this.sessionHasLogs;
    const status = selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const validStatus = StatusesWithLogs.includes(status);
    const canView = hasLogs && validStatus;

    return canView;
  }

  public get canViewSessionSteps(): boolean {
    // If a step is selected, and the status is failed or succeeded, it can also be viewed.
    const canView = this.isSessionStepsSelected;

    return canView;
  }
  public get hasPanelMessages(): boolean {
    const hasMessages = this.sessionPanelMessages.length > 0;

    return hasMessages;
  }

  public get isAirSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.AIR) || false;
  }

  public get isLabsSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.LABS) || false;
  }

  public get isSessionStepsButtonDisabled(): boolean {
    // Disabled if a step cannot be viewed or the popup is already open.
    const disabled = !this.canViewSessionSteps || this.isSessionStepPopupOpen;

    return disabled;
  }

  public get isSessionStepsSelected(): boolean {
    // If the step details exists, it has been selected.
    const selected = !!this.sessionStep;

    return selected;
  }

  public get selectedSessionRoutePath(): string {
    const sessionsStore = this.sessionsStore;
    const { selectedSession } = sessionsStore;

    if (!selectedSession) {
      // A session must be selected to build the path.
      return '';
    }

    const experimentPathPrefix: string = Navigation.GANYMEDE.SESSIONS;
    const experimentId: string = selectedSession.id;

    return `${experimentPathPrefix}/${experimentId}`;
  }

  public get sessionHasLogs(): boolean {
    // If the logs object is not empty, we have some logs.
    const hasLogs = this.sessionLogs?.length > 0 || false;

    return hasLogs;
  }

  public get stepCount(): number {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;
    const count = (isSessionSelected && selectedSession.steps?.length) || 0;

    return count;
  }

  public get panelMessageCount(): number {
    const count = this.sessionPanelMessages.length;

    return count;
  }
}

export default SessionDetailsStore;
