import { CancelToken } from 'axios';
import { t } from 'i18next';
import JSON5 from 'json5';

import { ExperimentTemplate } from '@/components/ExperimentEditor/ExperimentEditorTypes';
import { LabType } from '@/components/Experiments/ExperimentsTypes';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import { ExperimentResult, WorkflowParameter } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import LabSystemsStore from '@/components/ManageLab/LabSystems/LabSystemsStore';
import { LabUpgrade } from '@/constants/ExperimentEditorConstants';
import { DataType, Namespaces as NS } from '@/constants/SystemConstants';
import { Errors, Labs } from '@/constants/TranslationConstants';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { HandleError } from '@/utils/_labs/HandleError';

class LabDetailsViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected labDetailsStore: LabDetailsStore;
  protected labSystemsStore: LabSystemsStore;
  protected systemMessageStore: SystemMessageStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labDetailsStore, labSystemsStore, systemMessageStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.labDetailsStore = labDetailsStore;
    this.labSystemsStore = labSystemsStore;
    this.systemMessageStore = systemMessageStore;
  }

  fetchLabs = async (): Promise<void> => {
    const { selectedLabId, setIsLabBusy, setIsPersistedLab, setLabs, setSelectedLab } = this.labDetailsStore;

    try {
      const response: LabType[] = await ganymedeLabRequestService.getLabs();

      if (response?.length > 0) {
        setLabs(response);

        if (selectedLabId) {
          const storedLab: LabType = response.find((lab: LabType) => lab.LabId == selectedLabId);

          if (storedLab) {
            setSelectedLab(storedLab);
            setIsPersistedLab(true);

            return;
          }
        }

        setSelectedLab(response[0]); // Set the first lab as the default selected lab
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        appSettingsStore: this.appSettingsStore,
      };

      HandleError(handleErrorProps);
    } finally {
      setIsLabBusy(false);
    }
  };

  handleLabChange = (lab: LabType): void => {
    const { setIsPersistedLab, setSelectedLab } = this.labDetailsStore;
    const { setAgentIds } = this.labSystemsStore;

    setSelectedLab(lab);
    setIsPersistedLab(false);
    setAgentIds([]);
  };

  checkUpdate = (manifestVersion: string): void => {
    const { selectedLab, setUpdateAvailable } = this.labDetailsStore;

    if (selectedLab?.Version === manifestVersion || !selectedLab?.Version) {
      setUpdateAvailable(false);
      return;
    }

    const manifestVersionList = manifestVersion.split('.').map(Number);
    const labVersionList = selectedLab?.Version.split('.').map(Number);

    const updateAvailable: boolean = manifestVersionList.some((version, index) => labVersionList[index as number] < version);

    if (updateAvailable) {
      setUpdateAvailable(true);
    }
  };

  fetchLabManifest = async (): Promise<void> => {
    try {
      const result: string = await ganymedeLabRequestService.getLabManifest();

      this.labDetailsStore.setManifestVersion(result);
      this.checkUpdate(result);
    } catch (error) {
      const handleErrorProps = {
        error,
        systemMessageStore: this.systemMessageStore,
        appSettingsStore: this.appSettingsStore,
      };

      HandleError(handleErrorProps);
    }
  };

  replaceLabUpgradeValues = async (templateExperiment: any): Promise<any> => {
    const { manifestVersion, selectedLab } = this.labDetailsStore;

    const updateValues = (object: WorkflowParameter) => {
      const traverseArray = (array: WorkflowParameter[]) => {
        array.forEach((item: WorkflowParameter) => {
          if (typeof item === DataType.OBJECT) {
            updateValues(item);
          }
        });
      };

      for (const key in object) {
        if (Array.isArray(object[key as string])) {
          traverseArray(object[key as string]);
        } else if (typeof object[key as string] === DataType.OBJECT) {
          updateValues(object[key as string]);
        } else {
          if (key.toLowerCase() === LabUpgrade.LAB_ID) {
            object[key as string] = selectedLab?.LabId.toString();
          }

          if (key.toLowerCase() === LabUpgrade.MANIFEST_VERSION) {
            object[key as string] = manifestVersion;
          }
        }
      }
    };

    templateExperiment?.workflow.forEach((workflow) => {
      if (workflow.parameters) {
        updateValues(workflow.parameters);
      }
    });

    return templateExperiment;
  };

  createExperiment = async (json: string, cancellationToken: CancelToken): Promise<void> => {
    const { selectedLab, setCreateMessage, setFailedExperiments, setIsExperimentLoading, setSuccessfulExperiments } =
      this.labDetailsStore;
    const selectedLabId = selectedLab?.LabId.toString();

    try {
      const response = await ganymedeExperimentRequestService.queueExperiment(selectedLabId, json, [], false, cancellationToken);
      const result = JSON5.parse(response?.second);

      if (result?.created) {
        const successfulResult: ExperimentResult[] = [
          {
            labId: selectedLabId,
            instanceId: result.id,
          },
        ];

        setSuccessfulExperiments(successfulResult);
        setCreateMessage(t(Errors.QUEUED_EXPERIMENT_SUCCESS, { ns: NS.EDITOR }));
        setIsExperimentLoading(false);
      } else {
        const errorMessage = result?.Detail || result?.detail;
        const errorResult: ExperimentResult[] = [
          {
            labId: selectedLabId,
            error: errorMessage,
          },
        ];

        setFailedExperiments(errorResult);
        setCreateMessage(t(Errors.QUEUED_EXPERIMENT_ERROR, { ns: NS.ERRORS }));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data?.Detail || error.response.data?.detail : error.message;
      const errorResult: ExperimentResult[] = [
        {
          labId: selectedLabId,
          error: errorMessage,
        },
      ];

      setFailedExperiments(errorResult);
      setCreateMessage(t(Errors.QUEUED_EXPERIMENT_ERROR, { ns: NS.ERRORS }));
      setIsExperimentLoading(false);
    }
  };

  upgradeLab = async (cancellationToken: CancelToken): Promise<void> => {
    const { isDebugMode } = this.appSettingsStore;
    const { setIsExperimentLoading, setIsLabUpgradeModalOpen } = this.labDetailsStore;

    const message: string = t(Labs.UPDATE_LAB_CONFIRMATION, { ns: NS.LABS });

    if (!confirm(message)) {
      isDebugMode && console.log('[LabDetailsViewModel] User has aborted the updation of lab');
      return;
    }

    setIsLabUpgradeModalOpen(true);

    try {
      setIsExperimentLoading(true);

      const templateId = LabUpgrade.LAB_UPGRADE_TEMPLATE_NAME;
      const template: ExperimentTemplate = await ganymedeExperimentRequestService.getExperimentTemplateByName(templateId);

      const updatedTemplate: string = await this.replaceLabUpgradeValues(template?.experiment);

      this.createExperiment(updatedTemplate, cancellationToken);
    } catch (error) {
      isDebugMode && console.error('[LabDetailsViewModel] Lab Upgrade failure:', error.message);
      setIsExperimentLoading(false);
    }
  };
}

export default LabDetailsViewModel;
