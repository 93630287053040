import { action, computed, makeObservable, observable } from 'mobx';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import { ExperimentResult } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LabDetailsStore {
  protected localStorage: LocalStorageService;
  protected rootStore: RootStore;

  public createMessage: string;
  public failedExperiments: ExperimentResult[] = [];
  public isExperimentLoading: boolean;
  public isLabBusy = true;
  public isLabUpgradeModalOpen: boolean;
  public isPersistedLab = false;
  public labList: LabType[];
  public manifestVersion: string;
  public selectedLab: LabType = null;
  public selectedLabId: number = null;
  public successfulExperiments: ExperimentResult[] = [];
  public updateAvailable: boolean;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      createMessage: observable,
      failedExperiments: observable,
      isExperimentLoading: observable,
      isLabBusy: observable,
      isLabUpgradeModalOpen: observable,
      isPersistedLab: observable,
      labList: observable,
      manifestVersion: observable,
      selectedLab: observable,
      selectedLabId: observable,
      successfulExperiments: observable,
      updateAvailable: observable,

      // Computed values.
      hasFailedExperiments: computed,
      hasSuccessfulExperiments: computed,

      // Actions modify the state.
      setCreateMessage: action,
      setFailedExperiments: action,
      setIsExperimentLoading: action,
      setIsLabBusy: action,
      setIsLabUpgradeModalOpen: action,
      setIsPersistedLab: action,
      setLabs: action,
      setManifestVersion: action,
      setSelectedLab: action,
      setSuccessfulExperiments: action,
      setUpdateAvailable: action,
    });

    this.localStorage = rootStore.localStorage;
    this.rootStore = rootStore;

    this.isExperimentLoading = false;
    this.isLabUpgradeModalOpen = false;
    this.manifestVersion = '';
    this.selectedLabId = this.localStorage.getValue(AppSettingsService.LAB_DETAILS_LAB_KEY) || null;
    this.updateAvailable = false;
  }

  public setCreateMessage = (message: string): void => {
    this.createMessage = message;
  };

  public setFailedExperiments = (experiments: ExperimentResult[]): void => {
    this.failedExperiments = experiments;
  };

  public setIsExperimentLoading = (isLoading: boolean): void => {
    this.isExperimentLoading = isLoading;
  };

  public setIsLabUpgradeModalOpen = (isOpen: boolean): void => {
    this.isLabUpgradeModalOpen = isOpen;
  };

  public setIsLabBusy = (busy: boolean): void => {
    this.isLabBusy = busy;
  };

  // To identify whether lab change is only upon lab dropdown selection change
  // This helps to persist the filter and group by values to restore
  public setIsPersistedLab = (persisted: boolean): void => {
    this.isPersistedLab = persisted;
  };

  public setLabs = (labs: LabType[]): void => {
    this.labList = labs;
  };

  public setManifestVersion = (version: string): void => {
    this.manifestVersion = version;
  };

  public setSelectedLab = (lab: LabType): void => {
    this.selectedLab = lab;
    this.setSelectedLabId(lab.LabId);
  };

  private setSelectedLabId = (id: number): void => {
    this.selectedLabId = id;
    this.localStorage.setValue(AppSettingsService.LAB_DETAILS_LAB_KEY, id);
  };

  public setSuccessfulExperiments = (experiments: ExperimentResult[]): void => {
    this.successfulExperiments = experiments;
  };

  public setUpdateAvailable = (isAvailable: boolean): void => {
    this.updateAvailable = isAvailable;
  };

  get hasFailedExperiments(): boolean {
    return this.failedExperiments?.length > 0;
  }

  get hasSuccessfulExperiments(): boolean {
    return this.successfulExperiments?.length > 0;
  }
}

export default LabDetailsStore;
