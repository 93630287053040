import React from 'react';
import { CsvToHtmlTable } from 'react-csv-to-table';
import JSONPretty from 'react-json-pretty';
import { observer } from 'mobx-react-lite';
import { DefaultButton, IconButton, Modal, SpinnerSize } from '@fluentui/react';
import { Card, Result, Tree } from 'antd';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import mime from 'mime';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Messages } from '@/constants/LabsConstants';
import { Delimiters, FileType, MimeType, Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';

const { DirectoryTree } = Tree;

interface DataNode {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: DataNode[];
}

const RawLogsViewFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [pathData, setPathData] = React.useState<any>();
  const [treeData, setTreeData] = React.useState<any>();
  const [treeLoading, setTreeLoading] = React.useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [content, setContent] = React.useState<any>();
  const [modalContentLoading, setModalContentLoading] = React.useState(true);
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [selectedFilePath, setSelectedFilePath] = React.useState('');
  const [error, setError] = React.useState(true);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [cardWidth, setCardWidth] = React.useState('auto');
  const defaultCardWidth = '0px';
  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const treeHeight: number = props.treeHeight;
  const treeIconClass = '.ant-tree-icon__customize';
  const treeTitleClass = 'ant-tree-title';

  let pNode: any[] = [];

  const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }

      return node;
    });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const getPathLengthToIgnore = (paths: any) => {
    let outputPath = '';
    let prev_split_len = -1;
    let previous = '';
    let current = '';

    for (let ind = 0; ind < paths.length - 1; ind++) {
      const prev_split = paths[ind as number].split('/');
      const current_split = paths[ind + 1].split('/');

      if (outputPath) {
        previous = prev_split.slice(0, prev_split_len).join('/');
        current = current_split.slice(0, prev_split_len).join('/');
      } else {
        previous = prev_split.slice(0, -1).join('/');
        current = current_split.slice(0, -1).join('/');
      }

      if (previous === current) {
        if (outputPath.length === 0) {
          outputPath = previous;
          prev_split_len = prev_split.length;
        }
      } else if (previous !== outputPath) {
        prev_split_len -= 1;
        outputPath = prev_split.slice(0, prev_split_len).join('/');
      }
    }

    outputPath = outputPath.split('/').slice(0, -1).join('/');

    return outputPath.length === 0 ? 0 : outputPath.length + 1;
  };

  const isFile = (pathsInfo: any, fileName: string) => {
    const result = pathsInfo.find((obj: any) => obj.name.split('/').pop() === fileName);

    if (result) {
      return !result.isDirectory;
    }

    return false;
  };

  const insert = (pathsInfo: any, children: any = [], [head, ...tail]: any) => {
    let child: any = children.find((child: any) => child.title === head);

    pNode.push([head][0]);

    if (!child) {
      children.push(
        (child = {
          title: head,
          key: `${pNode.join(Delimiters.FORWARD_SLASH)}`,
          children: [],
          isLeaf: isFile(pathsInfo, head),
        }),
      );
      pNode = [];
    }

    if (tail.length > 0 && typeof child !== 'undefined') insert(pathsInfo, child.children, tail);

    return children;
  };

  const getFolderData = async (type: string, path: string, companyName: string) => {
    let folderData = [];

    if (type === 'file') {
      folderData = await logManagementRequestService.getSpecificFileByPath(path, companyName, false);
    } else {
      folderData = await logManagementRequestService.getFilePathsById(path, companyName, false);
    }

    return folderData;
  };

  const generateDirectoryTree = (pathsInfo: any) => {
    const allPaths = pathsInfo.map((pathInfo: any) => pathInfo.name);
    const pathToIgnore = getPathLengthToIgnore(allPaths);

    /** Commenting nonEmptyPaths as directory is created inconsistently in UI */
    const nonEmptyPaths = allPaths.map((path: any) => path.substring(pathToIgnore)).filter((e: any) => e);

    const objectArray = allPaths
      .map((path: any) => `/${path}`.split('/').slice(1))
      .reduce((children: any, path: any) => insert(pathsInfo, children, path), []);

    objectArray.forEach((element: any) => {
      element.isRoot = 'true';
    });

    return objectArray;
  };

  const getDetails = (type, folder) => {
    getFolderData(type, folder, props.companyName)
      .then((res) => {
        const directoryTreeData = generateDirectoryTree(res);

        if (props.testName !== undefined) {
          const testLogTreeData = directoryTreeData[0].children.filter((tree: any) => tree.title === props.testName);

          setTreeData(testLogTreeData);
        } else {
          setTreeData(directoryTreeData);
        }

        setPathData(res);
        setError(false);
        setTreeLoading(false);
      })
      .catch((error: any) => {
        setTreeLoading(false);
      });
  };

  React.useEffect(() => {
    if (props.id) {
      setTreeLoading(true);
      const type = props.type !== undefined && props.type === 'file' ? props.type : 'folder';
      const folder = type === 'file' ? props.path.folder : props.id;

      getDetails(type, folder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const isAFile = (fileName: string) => {
    // MimeType.VND_LOTUS This is validated due to bug (28696018)
    // IP (739,946daea41114,14,192.168.1.123) ends with .123 somehow to be considered as this mime type
    const mimeType = mime.getType(fileName) !== MimeType.VND_LOTUS && mime.getType(fileName) !== null;

    return mimeType;
  };

  const onSelect = (keys: any[], info: any) => {
    const target = info.nativeEvent.target as HTMLElement;

    const isIconClicked = target.closest(treeIconClass) !== null;
    const isTextClicked = target.classList.contains(treeTitleClass);

    if (isIconClicked || isTextClicked) {
      const keySplit = keys[0].split('/');
      const key = keySplit[keySplit.length - 1];
      const filteredPathData = pathData.filter((pathObject) => pathObject.name.includes(keys[0]));

      if (filteredPathData.length > 0) {
        const filePath = filteredPathData[0].name;

        setSelectedFilePath(filePath);

        if (isAFile(key)) {
          setSelectedFileName(key);
          setModalContentLoading(true);
          setIsModalVisible(true);

          logManagementRequestService.downloadLogFile(filePath, props.companyName).then((downloadedContent) => {
            setContent(downloadedContent);
            setModalContentLoading(false);
          });
        }
      }
    }
  };

  const onLoadData = ({ key, children }: any) =>
    new Promise<void>((resolve) => {
      if (children.length !== 0) {
        resolve();

        return;
      }

      setTimeout(() => {
        const filePath = pathData.filter((pathObject) => pathObject.name.includes(key))[0].name;

        setSelectedFilePath(filePath);

        getFolderData('folder', filePath, props.companyName).then((res) => {
          const temp = res.map((fileDetail) => ({
            title: fileDetail.name.split('/').pop(),
            key: fileDetail.name,
            isLeaf: isAFile(fileDetail.name.split('/').pop()),
            children: [],
          }));

          setTreeData((origin) => updateTreeData(origin, key, temp));

          setPathData((prevPathData) => [...prevPathData, ...res]);
          resolve();
        });
      }, TimeInterval.ONE_SECOND);
    });

  const failedResult = <Result status="error" title={Messages.logsNotFound} subTitle={Messages.logsNotFoundDescription} />;

  const downloadBlob = () => {
    setDownloadLoading(true);
    logManagementRequestService
      .downloadLogFile(selectedFilePath, props.companyName)
      .then((res) => {
        setDownloadLoading(false);

        if (selectedFileName.endsWith(FileType.JSON) || selectedFileName.endsWith(FileType.LOG)) {
          res = JSON.stringify(res);
        }

        const blob = new Blob([res], {
          type: MimeType.BINARY_FILE,
        });

        saveAs(blob, selectedFileName);
      })
      .catch((error) => {
        setDownloadLoading(false);

        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  React.useLayoutEffect(() => {
    setCardWidth(defaultCardWidth);
    const cardElement = document.getElementById('cardLogFile');

    if (cardElement) {
      setCardWidth(`${cardElement.offsetWidth}px`);
    }
  }, [modalContentLoading]);

  if (treeLoading) {
    return <LoadingSpinner size={SpinnerSize.medium} />;
  }

  const title = `${t('view-file', { ns: NS.TITLES })} ${TITLE_DIVIDER} ${selectedFileName}`;

  return (
    <Card loading={treeLoading} className="ganymede-wrapper results-table">
      {error ? (
        failedResult
      ) : (
        <>
          {treeHeight ? (
            <DirectoryTree
              defaultExpandAll
              treeData={treeData}
              multiple={false}
              loadData={onLoadData}
              onSelect={onSelect}
              height={treeHeight}
            />
          ) : (
            <DirectoryTree
              defaultExpandAll
              treeData={treeData}
              multiple={false}
              loadData={onLoadData}
              onSelect={onSelect}
              rootStyle={SidePanelStyles.logView}
            />
          )}
          <Modal
            className="labs-wrapper"
            aria-busy={modalContentLoading}
            isOpen={isModalVisible}
            titleAriaId="basicModal"
            isBlocking={false}
            containerClassName={contentStyles.min35Container}
            onDismiss={() => setIsModalVisible(false)}
          >
            {cardWidth != defaultCardWidth && (
              <div className={contentStyles.header}>
                <h2 className={contentStyles.heading}>{title}</h2>
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} onClick={() => setIsModalVisible(false)} />
              </div>
            )}
            <div className="row">
              <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
            </div>
            <div className={contentStyles.body}>
              <Card id="cardLogFile" loading={modalContentLoading} className="results-table background text-color logs-data">
                {selectedFileName.endsWith('.csv') ? (
                  <div className="csv-table-container">
                    <CsvToHtmlTable
                      data={content}
                      csvDelimiter=","
                      tableClassName="table table-striped table-hover background text-color"
                    />
                  </div>
                ) : (
                  <JSONPretty id="json-pretty" data={content} className="background text-color" />
                )}
              </Card>
              {!modalContentLoading && (
                <DefaultButton title={t('download-file', { ns: NS.DEFAULT })} onClick={downloadBlob}>
                  {t('download-file', { ns: NS.DEFAULT })}
                </DefaultButton>
              )}
            </div>
          </Modal>
        </>
      )}
    </Card>
  );
};

export const RawLogsView = observer(RawLogsViewFC);
