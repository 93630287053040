import React from 'react';
import ReactJson from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { observer } from 'mobx-react-lite';
import { IconButton, Pivot, PivotItem } from '@fluentui/react';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import customStyle from '@/components/SessionDetails/SessionDetailsStyles';
import config from '@/components/Sessions/Sessions.config.json';
import SessionStepChartTemplate from '@/components/SessionStepChart/SessionStepChartTemplate';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { Statuses } from '@/constants/ExperimentConstants';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { ChartType, Namespaces as NS, SplitPanelDirectionType } from '@/constants/SystemConstants';
import { LogsView, Session } from '@/constants/TranslationConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import SessionInfoBlock from '@/partials/SessionInfoBlock/SessionInfoBlockTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import { SessionDetailsVCType } from './SessionDetailsTypes';

import '@/styles/SplitPanel.css';
import styles from './SessionDetails.module.css';

const SessionDetailsTemplateFC: React.FC<SessionDetailsVCType> = (props: SessionDetailsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionDetailsStore, sessionsStore } = rootStore;

  const { isOutlookMode, tabMemory, isReadingPaneBottom, isReadingPaneRight } = appSettingsStore;
  const {
    isSessionSelected,
    isSessionDataLoaded,
    isViewingDeepLink,
    labCompanyName,
    readingPaneKey,
    selectedSession,
    selectedExperimentFailure,
    selectedSessionId,
    selectedSessionIsCancellable,
    sessionFailureAnalysis,
  } = sessionsStore;
  const {
    canViewInstanceResults,
    closeSessionStepColumnEditor,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    isLogWindowItemSelected,
    isSessionFailureWindowSelected,
    isSessionLoading,
    isSessionStepColumnEditorOpen,
    isSessionStepsSelected,
    isSessionStepWindowSelected,
    logPath,
    panelMessageCount,
    selectLogWindowItem,
    selectSessionStepWindow,
    selectFailureWindowItem,
    sessionExperimentFailureColumns,
    sessionStep,
    sessionStepColumnList,
    sessionStepColumnEditorKey,
    sessionStepEntireColumns,
    sessionStepGroupByValue,
    setSessionStepColumnsList,
  } = sessionDetailsStore;
  const {
    agentFilterItems,
    chart,
    checkboxVisibility,
    columnEditorUserSettings,
    compiledJsonStyle,
    content,
    detailsSplitDirection,
    farItems,
    filteredStep,
    generalCommandBarItems,
    groupByColumn,
    groupByColumns,
    onJsonLinkClick,
    onLinkClick,
    pageHeader,
    rawJsonStyle,
    selection,
    setContent,
    splitPanelConfig,
    stepChanges,
    stepDisplayItems,
    tableGroups,
  } = props;

  const activeTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL];
  const activeJsonTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL_JSON];
  const isLoadingSteps = !selectedSession?.steps;

  React.useEffect(() => {
    const generalContent = () => {
      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          <PageCommandBar items={generalCommandBarItems}></PageCommandBar>
          <div className={styles['panel-tab']}>
            <div className={styles['columns']}>
              <div className={styles['column']}>
                <SessionInfoBlock session={selectedSession}></SessionInfoBlock>
              </div>
              <div className={styles['multi-divider']}></div>
              <div className={styles['column']}>
                <SessionStepChartTemplate type={ChartType.DONUT} data={selectedSession}></SessionStepChartTemplate>
              </div>
            </div>
          </div>
        </>
      );
    };

    const stepsTabContent = () => {
      if (isLoadingSteps) {
        return (
          <div className={styles['panel-tab']}>
            <LoadingSpinner />
          </div>
        );
      }

      // NOTE: Eventually move these to the ViewController.
      const filteredSteps = filteredStep(stepDisplayItems);
      const selectedStepJson: object = isSessionSelected ? (sessionStep?.stepJSON as object) : {};
      const selectedFailureAnalysis: any = selectedExperimentFailure;
      const stepName: string = sessionStep?.name;

      return (
        <>
          <PageFilterBar items={agentFilterItems} farItems={farItems}></PageFilterBar>
          <div className={styles['panel-tab']}>
            <SplitPanel
              index={0}
              config={splitPanelConfig}
              isAllowResize={isOutlookMode || isViewingDeepLink}
              split={detailsSplitDirection}
            >
              <TableViewViewController
                checkboxVisibility={checkboxVisibility}
                columns={modifiedColumnConfiguration(sessionStepColumnList)}
                displayColumns={config.stepsDisplayFields}
                enableToolBar={false}
                tableStyle={styles['session-steps-table']}
                selection={selection}
                selectionMode={SelectionMode.single}
                groups={tableGroups}
                groupByColumn={groupByColumn}
                groupByColumns={groupByColumns}
                isCompact={true}
                isStickyHeader={true}
                items={filteredSteps}
              />
              <div className={`${styles['details-pane']}`}>
                {/* In Outlook Mode, we will remove the Title */}
                {!isOutlookMode && <div className={styles['step-details-title']}>{t('step-details', { ns: NS.EXPERIMENTS })}</div>}
                <div className={styles['step-details-container']}>
                  <SplitPanel
                    index={1}
                    config={splitPanelConfig}
                    isAllowResize={isOutlookMode || isViewingDeepLink}
                    split={SplitPanelDirectionType.VERTICAL}
                  >
                    <div className={`${styles['list-pane']}`}>
                      <div
                        className={`${styles['title']}${isSessionStepWindowSelected ? ` ${styles['hot']}` : ''}`}
                        onClick={() => selectSessionStepWindow()}
                      >
                        <IconButton
                          aria-label={t('steps-json', { ns: NS.EXPERIMENTS })}
                          iconProps={{ iconName: SystemIcons.STEPS_JSON }}
                        />
                        {t('steps-json', { ns: NS.EXPERIMENTS })}
                      </div>
                      <div className={styles['row-divider']}></div>
                      <div
                        className={`${styles['title']}${isLogWindowItemSelected ? ` ${styles['hot']}` : ''}`}
                        onClick={() => selectLogWindowItem()}
                      >
                        <IconButton
                          aria-label={t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
                          iconProps={{ iconName: SystemIcons.LOGS }}
                        />
                        {t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
                      </div>

                      {isLogWindowItemSelected && sessionStep?.experimentId && (
                        <>
                          <div className={styles['row-divider']}></div>
                          <RawLogsTreeView
                            id={sessionStep?.experimentId}
                            companyName={labCompanyName}
                            path={logPath}
                            showLogText={false}
                            isTitleHighlighted={isLogWindowItemSelected}
                          />
                        </>
                      )}
                      <div className={styles['row-divider']}></div>

                      {sessionFailureAnalysis && (
                        <div
                          className={`${styles['title']}${isSessionFailureWindowSelected ? ` ${styles['hot']}` : ''}`}
                          onClick={() => selectFailureWindowItem()}
                        >
                          <IconButton
                            aria-label={t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
                            iconProps={{ iconName: SystemIcons.FAILURE_ANALYSIS }}
                          />
                          {t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
                        </div>
                      )}
                    </div>
                    <div className={`${styles['content-pane']}`}>
                      <div className={styles['logs-view-container']}>
                        {isSessionStepWindowSelected && (
                          <>
                            {!isSessionStepsSelected && <>Select Step to view JSON</>}
                            {isSessionDataLoaded && isSessionStepsSelected && (
                              <>
                                <div className={`${styles['title']}`}>{stepName}</div>
                                <pre className={styles['json-block']}>{<ReactJson src={selectedStepJson} />}</pre>
                              </>
                            )}
                          </>
                        )}
                        {isSessionFailureWindowSelected && (
                          <>
                            {sessionFailureAnalysis && selectedFailureAnalysis !== null && (
                              <TableViewViewController
                                items={selectedFailureAnalysis}
                                columns={modifiedColumnConfiguration(sessionExperimentFailureColumns)}
                                displayColumns={config.sessionExperimentFailureDisplayFields}
                                enableToolBar={false}
                                enableColumnSort={true}
                                tableStyle={styles['session-steps-table']}
                                checkboxVisibility={checkboxVisibility}
                                isCompact={true}
                                isStickyHeader={true}
                              />
                            )}
                          </>
                        )}
                        {isLogWindowItemSelected && sessionStep && <FilePreview companyName={labCompanyName} />}
                      </div>
                    </div>
                  </SplitPanel>
                </div>
              </div>
            </SplitPanel>
            <ColumnEditorPanelTemplate
              columnEditorKey={sessionStepColumnEditorKey}
              columnsList={sessionStepColumnList}
              entireColumns={sessionStepEntireColumns}
              hideColumnEditor={closeSessionStepColumnEditor}
              isColumnEditorOpen={isSessionStepColumnEditorOpen}
              setColumnsList={setSessionStepColumnsList}
              userSettings={columnEditorUserSettings}
            ></ColumnEditorPanelTemplate>
          </div>
        </>
      );
    };

    const compiledJsonTabContent = () => {
      const json: object = isSessionDataLoaded ? (selectedSession.json as object) : {};

      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          {isSessionDataLoaded && (
            <>
              <pre className={styles['json-block']}>
                {<ReactJson src={json} style={customStyle.jsonBackground} theme={compiledJsonStyle} />}
              </pre>
            </>
          )}
        </>
      );
    };

    const rawJsonTabContent = () => {
      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          {isSessionDataLoaded && (
            <>
              <pre className={styles['json-block']}>
                <SyntaxHighlighter language="json" style={rawJsonStyle} customStyle={customStyle.jsonBackground} showLineNumbers>
                  {selectedSession.rawJson}
                </SyntaxHighlighter>
              </pre>
            </>
          )}
        </>
      );
    };

    const generalTabItem = (
      <PivotItem headerText={t('general', { ns: NS.EXPERIMENTS })} key={'tab-general'} itemKey={'tab-general'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionDataLoaded && generalContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const stepsTabItem = (
      <PivotItem headerText={t('steps', { ns: NS.EXPERIMENTS })} key={'tab-steps'} itemKey={'tab-steps'}>
        <PageDivider />
        {isSessionDataLoaded && stepsTabContent()}
      </PivotItem>
    );

    const compiledJsonTabItem = (
      <PivotItem headerText={t('compiled-json', { ns: NS.EXPERIMENTS })} key={'tab-compiled-json'} itemKey={'tab-compiled-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionDataLoaded && compiledJsonTabContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const rawJsonTabItem = (
      <PivotItem headerText={t('source-json', { ns: NS.EXPERIMENTS })} key={'tab-raw-json'} itemKey={'tab-raw-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionDataLoaded && selectedSession.rawJson && rawJsonTabContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const jsonTabItem = (
      <PivotItem headerText={t('json', { ns: NS.EXPERIMENTS })} key={'tab-json'} itemKey={'tab-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          <Pivot defaultSelectedKey={activeJsonTab} onLinkClick={onJsonLinkClick}>
            {isSessionDataLoaded && selectedSession.rawJson && rawJsonTabItem}
            {isSessionDataLoaded && compiledJsonTabItem}
          </Pivot>
        </ScrollableContent>
      </PivotItem>
    );

    const content = (
      <>
        <div className={`${styles['modal-body-content']} ${styles[readingPaneKey as string]}`}>
          {isSessionDataLoaded && (
            <>
              {/* NOTE: When displayed within a Side Panel, the title is rendered in that component. */}
              {/* We viewing a Deep Link, we repress the title. */}
              {isOutlookMode && !isViewingDeepLink && pageHeader}
              <Pivot defaultSelectedKey={activeTab} onLinkClick={onLinkClick}>
                {generalTabItem}
                {stepsTabItem}
                {jsonTabItem}
              </Pivot>
            </>
          )}
          {!isSessionDataLoaded && (
            <>
              {isViewingDeepLink && <LoadingSpinner />}
              {!isViewingDeepLink && (
                <div className={styles['centered-message']}>{t('select-experiment', { ns: NS.EXPERIMENTS })}</div>
              )}
            </>
          )}
        </div>
      </>
    );

    if (isSessionLoading) {
      setContent(<LoadingSpinner />);
    } else {
      setContent(content);
    }
  }, [
    canViewInstanceResults,
    chart,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    isLogWindowItemSelected,
    isReadingPaneBottom,
    isReadingPaneRight,
    isSessionLoading,
    isSessionSelected,
    isSessionStepColumnEditorOpen,
    isSessionStepWindowSelected,
    isSessionFailureWindowSelected,
    logPath,
    panelMessageCount,
    selectedSession,
    selectedSessionIsCancellable,
    selectedSessionId,
    sessionExperimentFailureColumns,
    sessionStep,
    sessionStepColumnList,
    sessionStepEntireColumns,
    sessionStepGroupByValue,
    setContent,
    setSessionStepColumnsList,
    stepChanges,
    tabMemory,
  ]);

  return (
    <div className={`${styles['details-body']} ${isOutlookMode ? styles['outlook-mode'] : ''}`}>
      <>{content}</>
    </div>
  );
};

const SessionDetailsTemplate = observer(SessionDetailsTemplateFC);

export default SessionDetailsTemplate;
