/*----------------------------------------------------------------------------------------------------------------------------
# Header.tsx: 
# This Component is our top-level Navigation Component. 
# All Navigation related GUI and CSS styling should originate here.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon } from '@fluentui/react';
import { t } from 'i18next';

import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS, PartnerDomains } from '@/constants/SystemConstants';
import { PartnerThemes } from '@/constants/ThemeConstants';
import HelpButton from '@/partials/HelpButton/HelpButton';
import SettingsButton from '@/partials/SettingsButton/SettingsButton';
import SettingsPanelTemplate from '@/partials/SettingsPanel/SettingsPanelTemplate';
import UserProfileViewController from '@/partials/UserProfile/UserProfileViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { azureDevOpsTemplate } from '@/utils/Env';

import Logo from '@/images/crc-logo-white.png';

import styles from './Header.module.css'; // Apply any Navigation level CSS Module styling here.

const HeaderFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, userInfoStore } = rootStore;
  const { isAirHidden, isLabsHidden, isPartnerMode } = appSettingsStore;
  const { hasAirPermissions, hasLabsPermissions, userName } = userInfoStore;

  const showAirApplication = hasAirPermissions && !isAirHidden && !isPartnerMode;
  const showLabsApplication = hasLabsPermissions && !isLabsHidden;
  const showAirOnly = showAirApplication && !showLabsApplication;
  const showLabsOnly = showLabsApplication && !showAirApplication;
  const rootLink = showAirOnly ? Navigation.AIR.HOME : showLabsOnly ? Navigation.LABS.HOME : Navigation.GANYMEDE.SESSIONS;

  const getCompanyName = (userName: string) => {
    setNavBarBackground(userName);

    if (userName && isPartnerMode) {
      if (userName.includes(PartnerDomains.LENOVO)) {
        return PartnerDomains.LENOVO;
      } else if (userName.includes(PartnerDomains.ZT)) {
        return PartnerDomains.ZT;
      } else if (userName.includes(PartnerDomains.INTEL)) {
        return PartnerDomains.INTEL;
      } else if (userName.includes(PartnerDomains.WIWYNN)) {
        return PartnerDomains.WIWYNN;
      } else if (userName.includes(PartnerDomains.VSEI)) {
        return PartnerDomains.VSEI;
      }
    }

    return PartnerDomains.MICROSOFT;
  };

  const setNavBarBackground = (name: string) => {
    if (userName && isPartnerMode) {
      if (name.includes(PartnerDomains.LENOVO)) {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.LENOVO]);
      } else if (name.includes(PartnerDomains.ZT)) {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.ZT]);
      } else if (name.includes(PartnerDomains.INTEL)) {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.INTEL]);
      } else if (name.includes(PartnerDomains.WIWYNN)) {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.WIWYNN]);
      } else if (name.includes(PartnerDomains.VSEI)) {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.VSEI]);
      } else {
        document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.MICROSOFT]);
      }
    } else {
      document.documentElement.style.setProperty('--navbarBackground', PartnerThemes[PartnerDomains.MICROSOFT]);
    }
  };

  return (
    <header className={styles['nav-menu']}>
      <div className={styles['branding']}>
        <Link to={rootLink}>
          <img className={styles['icon']} src={Logo} alt={t('ms-logo', { ns: NS.DEFAULT })} />
          <div className={`${styles['header']}`}>
            <Trans ns={NS.COMPANIES as Namespace}>{getCompanyName(userName)}</Trans>
          </div>
          {!isPartnerMode && (
            <div className={styles['title']}>
              <Trans ns={NS.DEFAULT as Namespace}>crc-long-name</Trans>
            </div>
          )}
        </Link>
      </div>
      <div className={styles['menu']}>
        <Link className={styles['menu-icon']} to={{ pathname: azureDevOpsTemplate }} target="_blank">
          <Icon
            iconName="Feedback"
            title={t('send-feedback', { ns: NS.DEFAULT })}
            aria-label={t('send-feedback', { ns: NS.DEFAULT })}
          />
        </Link>
        <div className={`${styles['menu-icon']} ${styles['contextual-menu']}`}>
          <HelpButton />
        </div>
        {userName && (
          <>
            <div
              className={`${styles['menu-icon']} ${styles['sign-in']}`}
              title={t('view-profile', { ns: NS.DEFAULT })}
              aria-label={t('view-profile', { ns: NS.DEFAULT })}
            >
              <UserProfileViewController />
            </div>
          </>
        )}
        <div className={`${styles['menu-icon']} ${styles['sign-in']}`}>
          <SettingsButton />
        </div>
      </div>
      <SettingsPanelTemplate></SettingsPanelTemplate>
    </header>
  );
};

const Header = observer(HeaderFC);

export default Header;
