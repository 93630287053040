import React from 'react';
import SplitPane from 'react-split-pane';
import { observer } from 'mobx-react-lite';

import { SplitPanelVCType } from './SplitPanelTypes';

import styles from './SplitPanel.module.css'; /* Leave placeholder for CSS styles. */

const SplitPanelTemplateFC: React.FC<SplitPanelVCType> = (props: SplitPanelVCType) => {
  const { children, config, defaultSize, index, isAllowResize, saveValue, split } = props;

  return (
    <SplitPane
      split={split}
      minSize={config.minSize[index as number]}
      maxSize={config.maxSize[index as number]}
      allowResize={isAllowResize}
      defaultSize={defaultSize}
      onChange={(size: number) => saveValue(index, size)}
    >
      {children}
    </SplitPane>
  );
};

const SplitPanelTemplate = observer(SplitPanelTemplateFC);

export default SplitPanelTemplate;
