/*----------------------------------------------------------------------------------------------------------------------------
# Routes.tsx: 
# This page controls the specific routing for the Labs sub-application.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LabsControl } from '@/components/_labs/Labs/LabsControl';
import { ViewPackages } from '@/components/_labs/ManagePackage/ViewPackages';
import { ReportList } from '@/components/_labs/PowerBIReports/ReportList';
import { ReportView } from '@/components/_labs/PowerBIReports/ReportView';
import { RegressionTestPassResults } from '@/components/_labs/RegressionTestPass/RegressionTestPassResults';
import { AddRule } from '@/components/_labs/ResultsExplorer/AddRule';
import Search from '@/components/_labs/ResultsExplorer/LogsSearch/LogsSearch';
import { ResultTriage } from '@/components/_labs/ResultTriage';
import { Navigation } from '@/constants/NavigationConstants';
import PageNotFound from '@/pages/404Page/404Page';
import LabDetailsHome from '@/pages/LabDetails/LabDetailsHomeTemplate';
import LabsHome from '@/pages/Labs/LabsHomeTemplate';
import LegacyHome from '@/pages/LegacyHome/LegacyHomeTemplate';
import ResultExplorerHome from '@/pages/ResultExplorer/ResultExplorerHomeTemplate';
import { LogFolderView } from '@/partials/LogFolderView/LogFolderView';
import { TestRun } from '@/partials/TestRun/TestRun';

export const LabsRedirects = (): React.ReactElement => {
  return (
    <Switch>
      <Route exact path={Navigation.VEGA.HOME}>
        <Redirect to={Navigation.GANYMEDE.LABS} />
      </Route>
      <Route exact path={Navigation.LABS.HOME}>
        <Redirect to={Navigation.GANYMEDE.LABS} />
      </Route>
      <Route exact path={Navigation.LABS.MY_DASHBOARD}>
        <Redirect to={Navigation.GANYMEDE.RESULT_EXPLORER} />
      </Route>
      <Route exact path={Navigation.LABS.MANAGE_PACKAGES}>
        <Redirect to={Navigation.LABS.MANAGE_PACKAGES} />
      </Route>
    </Switch>
  );
};

export const LabsLandingPages = (): React.ReactElement => {
  return <></>;
};

// These MUST be defined in order of overriding route preference.
const LabsRoutes = (): React.ReactElement => {
  return (
    // Here we make sure we are only dealing with a single LABS Route.
    <Route path={Navigation.LABS.HOME}>
      <Switch>
        <Route exact path={`${Navigation.LABS.LAB_CONTROL}/:params`} component={LabsControl} />
        <Route path={Navigation.LABS.ADD_RULE} component={AddRule} />
        <Route exact path={Navigation.LABS.MANAGE_PACKAGES} component={ViewPackages} />
        <Route exact path={Navigation.GANYMEDE.LABS} component={LabsHome} />
        <Route exact path={Navigation.GANYMEDE.LAB_DETAILS} component={LabDetailsHome} />
        <Route exact path={Navigation.GANYMEDE.RESULT_EXPLORER} component={ResultExplorerHome} />
        <Route exact path={Navigation.LABS.RESULT_SEARCH} component={Search} />
        <Route exact path={`${Navigation.LABS.TEST_RUN}/:params`} component={TestRun} />
        <Route exact path={`${Navigation.LABS.LOG_FOLDER_VIEW}/:params`} component={LogFolderView} />
        <Route exact path={`${Navigation.LABS.RESULT_TRIAGE}/:params`} component={ResultTriage} />
        <Route exact path={Navigation.LABS.VIEW_TEST_REPORTS} component={ReportList} />
        <Route exact path={`${Navigation.LABS.VIEW_REPORT}/:params`} component={ReportView} />
        <Route exact path={Navigation.LABS.RELEASE_TOP_RESULTS} component={RegressionTestPassResults} />

        {/* Show the 404 page and keep the path intact. */}
        <Route component={PageNotFound} />
      </Switch>
    </Route>
  );
};

export const LabsPartnerRoutes = (): React.ReactElement => {
  return (
    // Here we make sure we are only dealing with a single LABS Route.
    <Route path={Navigation.LABS.HOME}>
      <Switch>
        {/* These should be guarded pages, but currently are not as the prod site cannot read groups for users w/o admin consent */}
        <Route exact path={Navigation.GANYMEDE.LABS} component={LabsHome} />
        <Route exact path={Navigation.GANYMEDE.LAB_DETAILS} component={LabDetailsHome} />
        <Route exact path={`${Navigation.LABS.LAB_CONTROL}/:params`} component={LabsControl} />
        <Route exact path={`${Navigation.LABS.RESULT_TRIAGE}/:params`} component={ResultTriage} />

        {/* Unguarded pages */}
        <Route path={Navigation.LABS.ADD_RULE} component={AddRule} />
        <Route exact path={Navigation.LABS.MANAGE_PACKAGES} component={ViewPackages} />
        <Route exact path={Navigation.GANYMEDE.RESULT_EXPLORER} component={ResultExplorerHome} />
        <Route exact path={`${Navigation.LABS.TEST_RUN}/:params`} component={TestRun} />
        <Route exact path={`${Navigation.LABS.LOG_FOLDER_VIEW}/:params`} component={LogFolderView} />

        {/* The Root route must be last, as part of it is found in every other route. */}
        <Route exact path={Navigation.GANYMEDE.HOME} component={LegacyHome} />

        {/* Show the 404 page and keep the path intact. */}
        <Route component={PageNotFound} />
      </Switch>
    </Route>
  );
};

export default LabsRoutes;
