import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SplitPanelTemplate from './SplitPanelTemplate';
import { SplitPanelViewControllerProps } from './SplitPanelTypes';

const SplitPanelViewControllerFC: React.FC<SplitPanelViewControllerProps> = ({ config, children, index, isAllowResize, split }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, localStorage } = rootStore;
  const { isDebugMode } = appSettingsStore;

  const offModeSize: number[] | string[] = config.offModeSize;

  // Save the value to the local storage.
  const saveValue = (position: number, value: number): number => {
    const key: string = config.keys[position as number];
    const savedValue: number = Math.round(value);

    return localStorage.setValue(key, savedValue);
  };

  // Validate the size of the pane and handle edge cases.
  const validateSize = (position: number): number => {
    const key: string = config.keys[position as number];
    const rawValue: string = localStorage.getValue(key);
    const storedValue: number = Math.round(parseInt(rawValue, 10));
    const isValueTooSmall = storedValue < config.minSize[position as number];

    // If the stored value is not set or is less than the minSize, set it to the default size.
    // This allows us to cleanse bad or invalid values from the local storage.
    // These checks are important to prevent the pane from being resized to an invalid size,
    // which can happen if the window height changes after the value is stored.
    if (!storedValue || isValueTooSmall) {
      const defaultValue = config.defaultSize[position as number];
      const savedValue: number = Math.round(defaultValue);

      isDebugMode && console.log('[validateSize]', position, storedValue, '==>', savedValue);

      return saveValue(position, savedValue);
    }

    // Make sure the storedValue is a integer.
    saveValue(index, storedValue);

    isDebugMode && console.log('[validateSize]', position, storedValue, ' OK');

    return storedValue;
  };

  // If we are not allowed to resize, then we default to a full height or width;
  const defaultSize: number | string = isAllowResize ? validateSize(index) : offModeSize[index as number];

  return (
    <SplitPanelTemplate
      config={config}
      defaultSize={defaultSize}
      index={index}
      isAllowResize={isAllowResize}
      saveValue={saveValue}
      split={split}
    >
      {children}
    </SplitPanelTemplate>
  );
};

const SplitPanelViewController = observer(SplitPanelViewControllerFC);

export default SplitPanelViewController;
