/*----------------------------------------------------------------------------------------------------------------------------
# Routes.tsx: 
# This page controls the specific routing for the main CRC Portal application.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Navigation } from '@/constants/NavigationConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const GanymedeRedirectsFC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, userInfoStore } = rootStore;
  const { isAirHidden, isLabsHidden } = appSettingsStore;
  const { hasAirPermissions, hasLabsPermissions } = userInfoStore;

  const showAirApplication = hasAirPermissions && !isAirHidden;
  const showLabsApplication = hasLabsPermissions && !isLabsHidden;
  const noAppsVisible = !showAirApplication && !showLabsApplication;

  return (
    <Switch>
      {noAppsVisible && (
        <Route path="*">
          <Redirect to={Navigation.PAGE_NOT_FOUND} />
        </Route>
      )}

      {showAirApplication && !showLabsApplication && (
        <>
          <Route exact path={Navigation.ROOT}>
            <Redirect to={Navigation.AIR.HOME} />
          </Route>
          <Route path={Navigation.HOME}>
            <Redirect to={Navigation.AIR.HOME} />
          </Route>
          <Route path={Navigation.LABS.HOME}>
            <Redirect to={Navigation.PAGE_NOT_FOUND} />
          </Route>
        </>
      )}

      {showLabsApplication && !showAirApplication && (
        <>
          <Route exact path={Navigation.ROOT}>
            <Redirect to={Navigation.LABS.HOME} />
          </Route>
          <Route path={Navigation.HOME}>
            <Redirect to={Navigation.LABS.HOME} />
          </Route>
          <Route path={Navigation.AIR.HOME}>
            <Redirect to={Navigation.PAGE_NOT_FOUND} />
          </Route>
        </>
      )}

      {/* Deprecated routes: These routes are to redirect the user to new endpoints for the next few months. */}
      <Route path={Navigation.DEPRECATE.ACCESSIBILITY_REPORT}>
        <Redirect to={Navigation.GANYMEDE.ACCESSIBILITY_REPORT} />
      </Route>
      <Route path={Navigation.DEPRECATE.RELEASE_NOTES}>
        <Redirect to={Navigation.GANYMEDE.RELEASE_NOTES} />
      </Route>
      <Route path={Navigation.DEPRECATE.RESULT_EXPLORER}>
        <Redirect to={Navigation.GANYMEDE.RESULT_EXPLORER} />
      </Route>
      <Route path={Navigation.DEPRECATE.SESSIONS}>
        <Redirect to={Navigation.ROOT} />
      </Route>

      {/* Remaining routes. */}
      <Route path={Navigation.RELIABILITY}>
        <Redirect to={Navigation.GANYMEDE.INSIGHTS} />
      </Route>
      <Route path={Navigation.HOME}>
        <Redirect to={Navigation.GANYMEDE.SESSIONS} />
      </Route>
    </Switch>
  );
};

const GanymedeRedirects = observer(GanymedeRedirectsFC);

export { GanymedeRedirects };
