/*----------------------------------------------------------------------------------------------------------------------------
# App.tsx: 
# This page controls the routing and loads the appropriate Component for each given Route. 
# Selected Component is passed along to the Layout Component. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Navigation } from '@/constants/NavigationConstants';
import RegularPageViewController from '@/layouts/RegularPage/RegularPageViewController';
import AirRoutes, { AirRedirects } from '@/routes/_AirRoutes';
import LabsRoutes, { LabsPartnerRoutes, LabsRedirects } from '@/routes/_LabsRoutes';
import { GanymedeRedirects } from '@/routes/Redirects';
import { GanymedeRoutes } from '@/routes/Routes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import PageNotFound from '../pages/404Page/404Page'; // Keep until all routes are implemented.

// REFERENCES: https://stackoverflow.com/questions/33062830/using-react-router-with-a-layout-page-or-multiple-components-per-page
const AppRoutesFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, userInfoStore } = rootStore;
  const { isAirHidden, isLabsHidden, isPartnerMode } = appSettingsStore;
  const { hasAirPermissions, hasLabsPermissions } = userInfoStore;

  const showAirApplication = hasAirPermissions && !isAirHidden && !isPartnerMode;
  const showLabsApplication = hasLabsPermissions && !isLabsHidden;
  const subPortalRoots: string[] = [];

  // Add the appropriate routing based on permissions and options.
  showAirApplication && subPortalRoots.push(Navigation.AIR.HOME);
  showLabsApplication && subPortalRoots.push(Navigation.LABS.HOME);

  return (
    <>
      {/* Redirects must be declared first. */}
      {showAirApplication && <AirRedirects />}
      {showLabsApplication && <LabsRedirects />}
      <GanymedeRedirects />

      {/* Add all routes that lead to the default Regular View Controller. */}
      <Switch>
        <RegularPageViewController>
          {showAirApplication && <AirRoutes />}
          {showLabsApplication && isPartnerMode ? <LabsPartnerRoutes /> : <LabsRoutes />}
          <GanymedeRoutes />
        </RegularPageViewController>
      </Switch>
    </>
  );
};

const AppRoutes = observer(AppRoutesFC);

export default AppRoutes;
